

import * as React from "react"
import Layout from "../components/layout"
import { Link, navigate } from 'gatsby';

import Seo from "../components/seo"
import loadImg from "../images/loading.gif";

const getRelativeLink = (link) => {
    const clean = link.replace('https://gizapedia.hirusta.io/', '').split('/');
    return '/' + clean.join('/');
  }

export default (props) => {
    const [bilaketa, setBilaketa] = React.useState(props.location?.hash?.split('=')[1])
    const [encResults, setEncResults] = React.useState([])
    const [dicResults, setDicResults] = React.useState([])
    const [loading, setLoading] = React.useState(true);

    if (bilaketa !== props.location?.hash?.split('=')[1]) {
        window.location.reload()
    }

    React.useEffect(async () => {
        const enc = await fetch(
            `https://gizapedia.hirusta.io/wp-json/namespace/v1/bilatu-beta?s=${encodeURIComponent(bilaketa)}`
          ).catch((error) => {console.error(error) });
          const json = await enc.json();
          setEncResults(json.slice(0, 10))
        
        const dic = await fetch(
            `https://hiztegia.gizapedia.org/api/search-books?query=${encodeURIComponent(bilaketa)}`, {
                headers: {
                    'Authorization': 'Token ho04LCeL5AjQJIdKUJJcG6pDL6wIl5NN:JyDskC999Cn8mS9zS6Ayr13wkhmHG89M'
                }
            }
        ).catch((error) => {console.error(error) });
        if(dic.status == 200) {
            const jsonDic = await dic.json();
            setDicResults(jsonDic.results)
        } else {
            console.error('error API search')
        }

        setLoading(false)
        

    }, [bilaketa])
    
    return (
      <Layout  title={'Bilatu'}>
        <Seo title="Bilatu" />
        <h1 style={{ fontSize: '1.75em', textAlign: 'center', marginBottom: '1em' }}>Bilaketa: {decodeURIComponent(bilaketa)}</h1>
        {loading &&  (
            <img src={loadImg} style={{width: '200px', textAlign: 'center', display:'block', margin: '0 auto'}} />
        )}
        {!loading && (

            <div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="enc-emaitzak">
                        <h2 style={{fontSize: '1.5em'}}>Entziklopediako emaitzak</h2><hr />
                        {encResults.map((res) => {
                            return (
                                <div className="my-3" key={res.id}><h3 style={{
                                    fontSize: '1.25em'
                                }}><Link to={getRelativeLink(res.link)}>{res.title}</Link></h3>
                                                <span className="text-md font-normal my-1 block" dangerouslySetInnerHTML={{__html:res.excerpt}}></span>
                                                {res.categories.map((category) => (
                  <Link to={'/kategoria/' + category.slug} key={category.slug} className='btn-giza py-1' >{category.name}</Link>

                ))}
                                </div>
                            )
                        })}
                    </div>
                    <div className="dic-emaitzak">
                    <h2 style={{fontSize: '1.5em'}}>Hiztegiko emaitzak</h2><hr />
                    {dicResults.map((res) => {
                            return (
                                <div className="" key={res.id}><h3 style={{

                                    padding: '0.5em 0',
                                    fontSize: '1.25em',
                                    borderBottom: '1px solid #ddd'
                                }}><Link to={'https://hiztegia.gizapedia.org/hitza/' + res.slug}>{res.name}</Link></h3>
                                                {/*res.adierak.map((adiera, adieraIndex) => {
                                                    return (
                                                    <>
                                                    <span className="adiera" style={{padding: '0.05em 0', display: 'block'}}>
                                                        <strong>{adieraIndex + 1}. &nbsp; &nbsp;</strong>{adiera.name}
                                                    </span> <br />
                                                    </>
                                                    )
                                                })*/}
                                                {/* {JSON.stringify(res.adierak)} */}
                    
                    </div>
                )})}

                </div>
            </div>
            </div>
        )}
        
      </Layout>
    );
};